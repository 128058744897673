import React from "react";
import Routes from "@routes";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@material-ui/core/styles";
import { AppContext } from "@providers/app";
import * as themes from "@themes";
import FontLoader from 'react-google-font-loader';
import "react-toastify/dist/ReactToastify.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const fonts = require('./fonts.json');

function App() {
  const {
    theme: [theme],
  } = React.useContext(AppContext);

  const stripePromise = loadStripe(process.env.REACT_APP_STRAPI_PUBLIC_API_KEY || '');

  return (
   
    <React.Fragment>
      <FontLoader
        fonts={fonts}
      />
      <ThemeProvider theme={(themes as any)[theme]}>
        <Elements stripe={stripePromise}>
          <Routes/>
          <ToastContainer />
        </Elements>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
