import { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import * as Pages from "@pages";
import { ContainerWithMenu } from "@components";
import { Loading } from "@components";
import { useAuth } from '@providers/auth';
import SimpleAlerts from "@components/Aviso";
const signed_routes = require("./signed-routes.json");
const unsigned_routes = require("./unsigned-routes.json");
const admin_routes = require("./admin-routes.json");

export default function Routes() {
  const { signed, user } = useAuth();

  if (signed === true) {
    return (
      <Router>
        <Suspense fallback={<Loading />}>
          <Switch>
            <ContainerWithMenu>
              {Object.keys(signed_routes).map((namepage, key) => {
                const Page = (Pages as any)[namepage];
                const path = signed_routes[namepage];
                const isAdminRoute = admin_routes.includes(path);

                return (!isAdminRoute || user.admin) && (
                  <Route exact path={path} key={key}>
                    <Page />
                  </Route>
                );
              })}
            </ContainerWithMenu>
            <Route>
              <Pages.NotFound />
            </Route>
          </Switch>
        </Suspense>
      </Router >
    );
  } else {
    return (
      <Router>
        <Suspense fallback={<Loading />}>
          <SimpleAlerts />
          <Switch>
            {Object.keys(unsigned_routes).map((namepage, key) => {
              const Page = (Pages as any)[namepage];
              const path = unsigned_routes[namepage];
              return (
                <Route exact path={path} key={key}>
                  <Page />
                </Route>
              );
            })}
            <Route>
              <Pages.NotFound />
            </Route>
          </Switch>
        </Suspense>
      </Router>
    );
  }
}
